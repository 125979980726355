import React, { useCallback, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import {
  ButtonIcon,
  Chip,
  Flex,
  Heading,
  Tooltip,
  Typography,
  useBreakpointValue,
} from "@nestoca/ui";
import { debounce } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetApplicationsSearchResults } from "@shared/api/hooks/applications";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useCombobox } from "downshift";
import { useTranslation } from "next-i18next";
import { BsEnvelope } from "react-icons/bs";
import { SlRefresh } from "react-icons/sl";
import { toast } from "react-toastify";

import { applicationTransformer } from "@components/dashboard-table/utils";

import styles from "./dashboard-header.module.scss";
import { Search } from "./search";

import type { ItemOption } from "./search-item";
import type { Item } from "@nestoca/ui";
import type { UseComboboxStateChange } from "downshift";

const MIN_CHARACTERS_REQUIRED = 2;
const DEBOUNCE_TIME = 250;

export const DashboardHeader = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const gate = useGate();
  const queryClient = useQueryClient();

  const { t } = useTranslation("dashboard");

  const { isAuthenticated } = useAuth0();
  const { data: account } = useGetAccount(undefined, isAuthenticated);

  const isMobile = useBreakpointValue({ default: true, md: false });
  const isExternalBroker = gate.getRole() === "externalbroker";
  const isExternalBrokerMobile = isMobile && isExternalBroker;

  const { data, isError, isFetching } = useGetApplicationsSearchResults(
    {
      searchQuery,
      isExternalBroker,
    },
    {
      select: (data) => {
        const results = data.results?.map((application) =>
          applicationTransformer(application)
        );

        return { ...data, results };
      },
      enabled: !!searchQuery,
    }
  );

  if (isError) {
    toast(
      <Typography size={0}>{t("search.error", { ns: "error" })}</Typography>,
      {
        type: "error",
        toastId: "search-error",
      }
    );
  }

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["applications"] });
  };

  const onInputClear = () => {
    setSearchQuery("");
  };

  const debounceSetQuery = useCallback(
    (term: string) => debounce(setSearchQuery, DEBOUNCE_TIME)(term),
    [setSearchQuery]
  );

  const handleOnInputValueChange = useCallback(
    async ({ type, inputValue }: UseComboboxStateChange<Item<ItemOption>>) => {
      const term = inputValue || "";
      const inputChange =
        type === useCombobox.stateChangeTypes.InputChange &&
        term.length >= MIN_CHARACTERS_REQUIRED;

      if (inputChange) {
        debounceSetQuery(term);
        return;
      }
    },
    [debounceSetQuery]
  );

  return (
    <Flex
      className={clsx(styles["dashboard-header"], {
        [styles["dashboard-header--mobile"]]: isExternalBrokerMobile,
      })}
      align="center"
      as="header"
    >
      <Flex
        className={clsx(styles.content, {
          [styles["content--mobile"]]: isExternalBrokerMobile,
        })}
        align="center"
        justify={"between"}
        gap={5}
      >
        {account && (
          <Flex direction="column" gap={1}>
            <Chip
              label={account.email}
              size="small"
              variant="info"
              variantStyle="inverted"
              leadingIcon={<BsEnvelope />}
            />
            <Heading size={6} weight={7} as="h1" textColor="var(--color-brand)">
              {t("helloUser", { account })}
            </Heading>
          </Flex>
        )}
        {isAuthenticated && (
          <Flex
            align="center"
            gap={4}
            className={clsx({
              [styles["search--mobile"]]: isExternalBrokerMobile,
            })}
          >
            <Search
              results={data?.results || []}
              isFetching={isFetching}
              onInputClear={onInputClear}
              onInputValueChange={handleOnInputValueChange}
            />
            <ButtonIcon
              data-tooltip-id="refresh-button-tooltip"
              aria-label="refresh-button"
              isRound
              size="medium"
              variant="ghost"
              icon={<SlRefresh size={20} color="var(--color-midnight)" />}
              onClick={handleRefresh}
              data-dd-action-name="refresh"
            />
            <Tooltip
              id="refresh-button-tooltip"
              direction="left"
              title={t("refresh", { ns: "common" })}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
