import {
  Chip,
  HStack,
  Typography,
  VStack,
  useBreakpointValue,
  Flex,
} from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { ProgressTrackerMobile } from "@shared/ui";
import { ClippedContent } from "@shared/ui/components/clipped-content";
import { formatAddress } from "@shared/utils";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import { ProgressBarTracker } from "@components/progress-bar-tracker";

import styles from "./application-header.module.scss";

export const ApplicationHeader = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  const { t } = useTranslation("common");
  const { data: application } = useGetApplication({ id: applicationId });

  const isMobile = useBreakpointValue({ default: true, md: false });

  if (!application) {
    return null;
  }

  const { applicationState, property } = application;

  return (
    <>
      {!isMobile && (
        <ClippedContent
          align="center"
          justify="between"
          gap={4}
          as="header"
          className={styles["application-header"]}
        >
          <VStack align="start" gap={1}>
            <HStack>
              <Typography size={2} weight={7}>
                #{applicationId}
              </Typography>
              <Chip size="small" label={t("applicationId")} />
            </HStack>
            <Typography size={0} weight={4}>
              {formatAddress(property?.address)}
            </Typography>
          </VStack>
          <ProgressBarTracker
            className={styles["application-header__progress-bar"]}
            applicationState={applicationState}
            iconSize={18}
            containerWidth={"var(--size-sm)"}
          />
        </ClippedContent>
      )}
      {isMobile && (
        <Flex
          justify="between"
          direction="column"
          gap={6}
          as="header"
          className={styles["application-header"]}
        >
          <Flex align="center" gap={4}>
            <Link
              className={styles["back-link"]}
              data-dd-action-name="back"
              href={`/`}
            >
              <BsFillArrowLeftCircleFill size={24} />
            </Link>
            <VStack align="start" gap={1}>
              <HStack>
                <Typography weight={5} style={{ fontSize: "12px" }}>
                  #{applicationId}
                </Typography>
                <Chip size="small" label={t("applicationId")} />
              </HStack>
              <Typography size={0} weight={7}>
                {formatAddress(property?.address)}
              </Typography>
            </VStack>
          </Flex>
          <ProgressTrackerMobile
            application={application}
            className={styles["application-header__progress-tracker"]}
          />
        </Flex>
      )}
    </>
  );
};
