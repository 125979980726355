import { useMemo } from "react";

import { HorizontalStepper, Box } from "@nestoca/ui";
import {
  ApplicationState,
  PROGRESS_BAR_STATUS,
  PROGRESS_BAR_STATUSES,
} from "@shared/constants";
import { KeepI18ExprHappy } from "@shared/utils";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { RiRecordCircleLine } from "react-icons/ri";

import styles from "./progress-bar-tracker.module.scss";
import { getCurrentIndex } from "./utils";

export const ProgressBarTracker = ({
  applicationState,
  iconSize,
  className,
  containerWidth,
}: {
  applicationState: ApplicationState;
  className?: string;
  iconSize?: number;
  containerWidth?: string;
}) => {
  const { t } = useTranslation("applications");

  const statuses = useMemo(() => {
    if (applicationState !== "CLOSED") {
      return Object.keys(PROGRESS_BAR_STATUS).filter(
        (status) => status !== "CLOSED"
      );
    }

    return Object.keys(PROGRESS_BAR_STATUS);
  }, [applicationState]);

  const STEPS = useMemo(() => {
    return statuses.map((status) => {
      const statusKey = status as PROGRESS_BAR_STATUSES;
      const label = t(
        `application.status.${PROGRESS_BAR_STATUS[statusKey]}` as KeepI18ExprHappy
      );

      const tooltip = {
        title: label,
        subText: t(
          `application.hub.status.${PROGRESS_BAR_STATUS[statusKey]}.description` as KeepI18ExprHappy
        ),
      };

      return {
        label,
        tooltip:
          applicationState !== ApplicationState.Closed || statusKey === "CLOSED"
            ? tooltip
            : undefined,
      };
    });
  }, [t, applicationState, statuses]);

  const currentIndex = getCurrentIndex(applicationState);

  return (
    <Box className={styles["progress-bar"]} style={{ width: containerWidth }}>
      <HorizontalStepper
        currentStepIndex={currentIndex}
        steps={STEPS}
        tooltipDirection="bottom"
        hideLabel={true}
        iconSize={iconSize}
        className={clsx(className, {
          [styles["progress-bar__stepper--closed"]]:
            applicationState === ApplicationState.Closed,
        })}
        activeIcon={
          <RiRecordCircleLine
            size={iconSize}
            color={
              applicationState === ApplicationState.Closed
                ? "var(--color-primary-a-500)"
                : "var(--active-step-icon-color)"
            }
          />
        }
      />
    </Box>
  );
};
