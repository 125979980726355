import { Role } from "@nestoca/gate-core";
import { ApplicationState, PROGRESS_BAR_STATUSES } from "@shared/constants";

import { ApplicationTableItem } from "@components/dashboard-table/types";

import type {
  StandardApplicationStatusType,
  HubApplicationStatusType,
} from "@shared/constants";
type Props = {
  role: Role;
} & Pick<ApplicationTableItem, "currentStatus" | "contactMade">;

export const mapApplicationStateToStatus = ({
  currentStatus,
  contactMade,
  role,
}: Props): StandardApplicationStatusType | HubApplicationStatusType | null => {
  const isExternalBroker = role === "externalbroker";

  const applicationIsInBeforeSubmittedState = [
    ApplicationState.Created,
    ApplicationState.Submitted,
    ApplicationState.UnderRevision,
    ApplicationState.Reviewed,
  ].includes(currentStatus || "");

  /*
   * CONTACT_MADE is not an actual state,
   * we need to derive the state based on if the applicationState
   * is in a state before SUBMITTED_TO_UNDERWRITING and contactMade is defined
   */
  if (applicationIsInBeforeSubmittedState && contactMade) {
    return "CONTACT_MADE";
  }

  if (isExternalBroker) {
    switch (currentStatus) {
      case ApplicationState.Created:
      case ApplicationState.Submitted:
      case ApplicationState.UnderRevision:
      case ApplicationState.Reviewed:
        return PROGRESS_BAR_STATUSES.APPLICATION_RECEIVED;

      case ApplicationState.NotesSubmitted:
      case ApplicationState.LenderSubmitted:
        return PROGRESS_BAR_STATUSES.UNDERWRITING_IN_PROGRESS;

      case ApplicationState.LenderApproved:
      case ApplicationState.PendingCommitmentSignature:
      case ApplicationState.PendingConditions:
        return PROGRESS_BAR_STATUSES.CONDITIONALLY_APPROVED;

      case ApplicationState.Complete:
      case ApplicationState.NotaryAlerted:
        return PROGRESS_BAR_STATUSES.FILE_COMPLETE;

      case ApplicationState.Funded:
        return PROGRESS_BAR_STATUSES.FUNDED;

      default:
        return null;
    }
  } else {
    switch (currentStatus) {
      case ApplicationState.Created:
      case ApplicationState.Submitted:
        return "REFERRAL_COMPLETED";

      case ApplicationState.UnderRevision:
      case ApplicationState.Reviewed:
        return "APPLICATION_IN_PROGRESS";

      // This is where CONTACT_MADE status would live if it was a actual application state

      case ApplicationState.NotesSubmitted:
      case ApplicationState.LenderSubmitted:
      case ApplicationState.LenderApproved:
        return "SUBMITTED_TO_UNDERWRITING";

      case ApplicationState.PendingCommitmentSignature:
      case ApplicationState.PendingConditions:
        return "CONDITIONAL_APPROVAL";

      case ApplicationState.Complete:
      case ApplicationState.NotaryAlerted:
      case ApplicationState.Expired:
        return "FINAL_APPROVAL_CONFIRMED";

      case ApplicationState.Funded:
        return "FUNDED";

      case ApplicationState.Closed:
        return "CLOSED";

      case ApplicationState.Deleted:
        return "DELETED";

      default:
        return null;
    }
  }
};

export const mapStatusToApplicationState = (
  status: StandardApplicationStatusType
) => {
  switch (status) {
    case "REFERRAL_COMPLETED":
      return [ApplicationState.Created, ApplicationState.Submitted];
    case "APPLICATION_IN_PROGRESS":
      return [ApplicationState.UnderRevision, ApplicationState.Reviewed];
    case "SUBMITTED_TO_UNDERWRITING":
      return [
        ApplicationState.NotesSubmitted,
        ApplicationState.LenderSubmitted,
        ApplicationState.LenderApproved,
      ];
    case "CONDITIONAL_APPROVAL":
      return [
        ApplicationState.PendingCommitmentSignature,
        ApplicationState.PendingConditions,
      ];
    case "FINAL_APPROVAL_CONFIRMED":
      return [
        ApplicationState.Complete,
        ApplicationState.NotaryAlerted,
        ApplicationState.Expired,
      ];
    case "FUNDED":
      return [ApplicationState.Funded];
    default:
      return [];
  }
};

export const mapHubStatusToApplicationState = (
  status: HubApplicationStatusType
) => {
  switch (status) {
    case "APPLICATION_RECEIVED":
      return [
        ApplicationState.Created,
        ApplicationState.Submitted,
        ApplicationState.UnderRevision,
        ApplicationState.Reviewed,
      ];
    case "UNDERWRITING_IN_PROGRESS":
      return [
        ApplicationState.NotesSubmitted,
        ApplicationState.LenderSubmitted,
      ];
    case "CONDITIONALLY_APPROVED":
      return [
        ApplicationState.LenderApproved,
        ApplicationState.PendingCommitmentSignature,
        ApplicationState.PendingConditions,
      ];
    case "FILE_COMPLETE":
      return [ApplicationState.Complete, ApplicationState.NotaryAlerted];
    case "FUNDED":
      return [ApplicationState.Funded];
    default:
      return [];
  }
};
