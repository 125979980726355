import { useGate } from "@nestoca/gate-react";
import { ColumnDef } from "@tanstack/react-table";

import { ApplicationTableItem } from "../types";

export const useColumnsByRole = (
  baseColumns: ColumnDef<ApplicationTableItem>[]
) => {
  const gate = useGate();
  const role = gate.getRole();

  const columnsMap: Record<string, ColumnDef<ApplicationTableItem>[]> = {
    default: baseColumns.filter(({ id }) => {
      return id && !["rate", "closingDate"].includes(id);
    }),
    externalbroker: baseColumns.filter(({ id }) => {
      // rate is filtered out because now it returns 0 for all applications
      // can return it back after BE fix
      return id && !["tenantReferralId", "isDigital", "rate"].includes(id);
    }),
  };

  return columnsMap[role] || columnsMap.default;
};
