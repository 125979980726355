import { Application } from "@shared/constants";
import { getMainApplicant, getCoApplicantsByRole } from "@shared/utils";

import { ApplicationTableItem } from "@components/dashboard-table/types";

// Transform application data to a format that can be used by the table
export const applicationTransformer = (
  application: Application
): ApplicationTableItem => {
  const {
    id,
    isNonDigital,
    type,
    created,
    applicationState,
    product,
    closedLostReason,
    contactMade,
    heloc,
    mortgage,
    referralSnapshot,
  } = application;

  const { firstName, lastName } = getMainApplicant(application);

  const coApplicantsByRole = getCoApplicantsByRole(application);

  /* For refinance and renewal the mortgage amount returned by the BE
     already include mortgage balance + additional fund amount  */
  const loanValue =
    (mortgage?.mortgageAmountPremiumsIncluded || 0) + (heloc?.helocAmount || 0) || undefined;

  return {
    id: id,
    tenantReferralId: referralSnapshot?.tenantReferralId,
    isDigital: !isNonDigital,
    name: `${firstName} ${lastName}`,
    transactionType: type,
    productType: {
      type: product?.type,
      term: product?.term,
    },
    loanValue,
    startingDate: created,
    currentStatus: applicationState,
    closedLostReason,
    coApplicantsByRole,
    contactMade,
    rate: mortgage?.finalRate,
    heloc,
    closingDate: mortgage?.closingDate,
    mortgageAmountPremiumsIncluded: mortgage?.mortgageAmountPremiumsIncluded,
  };
};
