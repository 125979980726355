import React, { useMemo } from "react";

import { Item, Search as SearchUI } from "@nestoca/ui";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { ItemOption, SearchItem } from "./search-item";
import styles from "./search.module.scss";

import type { ApplicationTableItem } from "@components/dashboard-table/types";
import type { UseComboboxStateChange } from "downshift";

const MIN_CHARACTERS_REQUIRED = 2;

type Props = {
  results?: ApplicationTableItem[];
  isFetching: boolean;
  onInputClear: () => void;
  onInputValueChange: (
    changes: UseComboboxStateChange<Item<ItemOption>>
  ) => void;
};

export const Search = ({
  results,
  isFetching,
  onInputClear,
  onInputValueChange,
}: Props) => {
  const { push } = useRouter();

  const { t } = useTranslation("common");

  const items: Item<ItemOption>[] = useMemo(
    () =>
      (results || []).map(
        ({
          id: applicationId,
          name: applicantName,
          currentStatus,
          contactMade,
          coApplicantsByRole,
        }) => ({
          id: applicationId,
          value: applicantName,
          option: { currentStatus, contactMade, coApplicantsByRole },
        })
      ),
    [results]
  );

  const onChange = (item: Item<ItemOption>) => {
    push(`/${item.id}`);
  };

  return (
    <SearchUI
      className={styles.search}
      placeholder={t("search.placeholder")}
      noResultsText={t("noResultsFound")}
      minCharactersRequiredText={t("search.minCharactersRequired")}
      minimumQueryLength={MIN_CHARACTERS_REQUIRED}
      loading={isFetching}
      onChange={onChange}
      onInputClear={onInputClear}
      onInputValueChange={onInputValueChange}
      items={items}
      renderItem={SearchItem}
    />
  );
};
