import { Role } from "@nestoca/gate-core";
import { TenantSlug } from "@nestoca/multi-tenant";
import {
  ApplicationStateGroup,
  CURRENT_STATUS_FILTER,
  PRODUCT_TYPE_FILTERS,
  DIGITAL_STATUSES,
} from "@shared/constants";
import { getApplicationTypesByTenant } from "@shared/utils";
import { ColumnFiltersState } from "@tanstack/react-table";
import { TFunction } from "i18next";

import {
  mapHubStatusToApplicationState,
  mapStatusToApplicationState,
} from "@components/application-details/utils";
import {
  CurrentStatusFilter,
  CurrentStatusFilterHub,
  MappedStatusFilter,
} from "@components/dashboard-table/types";

export const mapColumnFilters = (
  columnFilters: ColumnFiltersState,
  role: Role
) => {
  const isExternalBroker = role === "externalbroker";

  const currentStatusFilters = columnFilters.find(
    ({ id }) => id === "currentStatus"
  ) as CurrentStatusFilter | CurrentStatusFilterHub;

  // If we don't have any filters for the current status, we don't need to map anything
  if (!currentStatusFilters) return columnFilters;

  const currentStatusFiltersByRole = isExternalBroker
    ? (currentStatusFilters as CurrentStatusFilterHub).value.flatMap(
        mapHubStatusToApplicationState
      )
    : (currentStatusFilters as CurrentStatusFilter).value.flatMap(
        mapStatusToApplicationState
      );

  const mappedCurrentStatusFilter: MappedStatusFilter = {
    id: "currentStatus",
    value: currentStatusFiltersByRole,
  };

  const mappedFilters = columnFilters.filter(
    ({ id }) => id !== "currentStatus"
  );

  mappedFilters.push(mappedCurrentStatusFilter);

  return mappedFilters;
};

// Default filters state for each column where filtering is enabled
export const getFiltersInitialState = (
  tenantSlug: TenantSlug | undefined,
  applicationStateGroup: ApplicationStateGroup,
  role: Role
): { id: string; value: string[] }[] => [
  {
    id: "productType",
    value: [...PRODUCT_TYPE_FILTERS],
  },
  {
    id: "isDigital",
    value: [...DIGITAL_STATUSES],
  },
  {
    id: "transactionType",
    value: [...getApplicationTypesByTenant(tenantSlug)],
  },
  {
    id: "currentStatus",
    value: [...CURRENT_STATUS_FILTER(role)[applicationStateGroup]],
  },
];

// Filter options for each column where filtering is enabled
export const getProductTypeFilterOptions = (t: TFunction<"dashboard">) =>
  PRODUCT_TYPE_FILTERS.map((productType) => ({
    value: productType,
    label: t(`table.productTypes.${productType}`),
  }));

export const getDigitalFilterOptions = (t: TFunction<"dashboard">) =>
  DIGITAL_STATUSES.map((digitalStatus) => ({
    value: digitalStatus,
    label: t(`table.digitalStatuses.${digitalStatus}`),
  }));

export const getTransactionTypeFilterOptions = (
  t: TFunction<"dashboard">,
  tenantSlug: TenantSlug | undefined,
  role?: Role
) =>
  getApplicationTypesByTenant(tenantSlug).map((applicationType) => {
    const type =
      role && role === "externalbroker" && applicationType === "RENEWAL"
        ? "TRANSFER"
        : applicationType;

    return {
      value: applicationType,
      label: t(`table.applicationTypes.${type}`),
    };
  });

export const getCurrentStatusFilterOptions = (
  t: TFunction<"dashboard">,
  applicationStateGroup: ApplicationStateGroup,
  role: Role
) =>
  CURRENT_STATUS_FILTER(role)[applicationStateGroup].map((currentStatus) => ({
    value: currentStatus,
    label: t(`table.currentStatuses.${currentStatus}`),
  }));
