import React from "react";

import { useGate } from "@nestoca/gate-react";
import { Chip, Flex, Item, Typography } from "@nestoca/ui";
import { ApplicationState } from "@shared/constants";
import { useTranslation } from "next-i18next";

import { mapApplicationStateToStatus } from "@components/application-details/utils";

import styles from "./search.module.scss";

import type { ApplicationTableCoApplicantsByRole } from "@components/dashboard-table/types";
import type { Applicant, Application } from "@shared/constants";

const getApplicantName = (applicant: Applicant) => {
  return `${applicant?.firstName || ""} ${applicant?.lastName || ""}`;
};

export type ItemOption = {
  currentStatus: ApplicationState | undefined;
  contactMade: Application["contactMade"];
  coApplicantsByRole: ApplicationTableCoApplicantsByRole;
};

export const SearchItem = (item: Item<ItemOption>) => {
  const { t } = useTranslation("dashboard");
  const gate = useGate();
  const role = gate.getRole();

  const { currentStatus, contactMade, coApplicantsByRole } = item.option || {};

  const mappedStatus = mapApplicationStateToStatus({
    currentStatus,
    contactMade,
    role,
  });

  const { nonGuarantorCoApplicants, guarantor } = coApplicantsByRole || {};

  const hasCoApplicantOrGuarantor =
    !!nonGuarantorCoApplicants?.length || !!guarantor;

  const coApplicantNames = nonGuarantorCoApplicants?.map(
    (applicant, index, array) =>
      getApplicantName(applicant) + `${index !== array.length - 1 ? ", " : ""}`
  );

  return (
    <Flex direction="column" wrap="wrap" grow={1} gap={1}>
      <Flex direction="column">
        <Typography size={0} weight={6}>
          {item.value}
        </Typography>

        <Flex align="center" justify="between">
          <Typography
            className={styles["application-id"]}
            size="00"
            weight={6}
          >{`#${item.id}`}</Typography>

          {currentStatus && mappedStatus && (
            <Chip
              size="small"
              label={t(`table.currentStatuses.${mappedStatus}`)}
            />
          )}
        </Flex>
      </Flex>

      {hasCoApplicantOrGuarantor && (
        <Flex className={styles["co-applicants"]} direction="column" gap={1}>
          {!!nonGuarantorCoApplicants?.length && (
            <Flex direction="column">
              <Typography size="00" weight={6}>
                {coApplicantNames}
              </Typography>

              <Typography size="00">
                {t("coApplicant", { ns: "common" })}
              </Typography>
            </Flex>
          )}

          {!!guarantor && (
            <Flex direction="column">
              <Typography size="00" weight={6}>
                {getApplicantName(guarantor)}
              </Typography>

              <Typography size="00">
                {t("guarantor", { ns: "common" })}
              </Typography>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
