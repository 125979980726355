import { useGate } from "@nestoca/gate-react";
import { Flex, useBreakpointValue } from "@nestoca/ui";
import { ClippedContent } from "@shared/ui/components/clipped-content";
import clsx from "clsx";
import Link from "next/link";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";

import styles from "./navigation-header.module.scss";

export type NavigationHeaderProps = {
  children?: React.ReactNode;
  href: string;
  label: string;
  className?: string;
};

export const NavigationHeader = ({
  children,
  href,
  label,
  className,
}: NavigationHeaderProps) => {
  const isMobile = useBreakpointValue({ default: true, md: false });

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const isExternalBrokerMobile = isExternalBroker && isMobile;

  return (
    <ClippedContent
      className={clsx(styles["navigation-header"], className, {
        [styles["navigation-header--mobile"]]: isExternalBrokerMobile,
      })}
      align="center"
      justify="between"
      gap={5}
      as="header"
    >
      <Flex align="center" justify="between" gap={5}>
        <Link
          className={clsx(styles.link, {
            [styles["link--mobile"]]: isExternalBrokerMobile,
          })}
          data-dd-action-name="back"
          href={href}
        >
          {isExternalBrokerMobile ? (
            <BsFillArrowLeftCircleFill size={24} />
          ) : (
            <MdArrowBack key="arrow-back-icon" color="var(--color-black)" />
          )}
          {label}
        </Link>
      </Flex>
      {children}
    </ClippedContent>
  );
};
