import {
  ApplicationState,
  PROGRESS_BAR_STATUSES,
  PROGRESS_BAR_STATUS_GROUPS,
} from "@shared/constants";

export const getCurrentStatus = (applicationState: ApplicationState) => {
  return Object.keys(PROGRESS_BAR_STATUS_GROUPS).find((status) =>
    PROGRESS_BAR_STATUS_GROUPS[status as PROGRESS_BAR_STATUSES].includes(
      applicationState
    )
  );
};

export const getCurrentIndex = (applicationState: ApplicationState) => {
  const currentStatus = getCurrentStatus(applicationState);
  if (!currentStatus || applicationState === "CLOSED") {
    return 0;
  }

  return Object.keys(PROGRESS_BAR_STATUS_GROUPS).indexOf(currentStatus) + 1;
};
